.dev-icons {
  text-align: center;
}

.competitive-sites-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  font-family: "Google Sans Regular";
}

.competitive-sites-inline > i {
  color: #868e96;
}

.competitive-sites-inline > i:hover {
  color: #645beb;
}
