@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"),
    url("./assests/fonts/Agustina.woff") format("woff");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assests/fonts/Montserrat-Regular.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Bold";
  src: local("Google Sans Bold"),
    url("./assests/fonts/GoogleSans-Bold.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Bold Italic";
  src: local("Google Sans Bold Italic"),
    url("./assests/fonts/GoogleSans-BoldItalic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Italic";
  src: local("Google Sans Italic"),
    url("./assests/fonts/GoogleSans-Italic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Medium";
  src: local("Google Sans Medium"),
    url("./assests/fonts/GoogleSans-Medium.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Medium Italic";
  src: local("Google Sans Medium Italic"),
    url("./assests/fonts/GoogleSans-MediumItalic.ttf") format("woff");
}
@font-face {
  font-family: "Google Sans Regular";
  src: local("Google Sans Regular"),
    url("./assests/fonts/GoogleSans-Regular.ttf") format("woff");
}
@font-face {
  font-family: "Garamond Regular";
  font-weight: 400;
  src: local("Garamond Regular"),
    url("./assests/fonts/Garamond-Regular.ttf") format("woff");
}
@font-face {
  font-family: "Europa Light";
  font-weight: 300;
  src: local("Europa Light"),
    url("./assests/fonts/Europa-Light.ttf") format("woff");
}
@font-face {
  font-family: "Acorn SemiBold";
  font-weight: 300;
  src: local("Acorn SemiBold"),
    url("./assests/fonts/Acorn-SemiBold.ttf") format("woff");
}
@font-face {
  font-family: "Quentin";
  font-weight: 300;
  src: local("Quentin"), url("./assests/fonts/Quentin.ttf") format("woff");
}
@font-face {
  font-family: "Didot";
  font-weight: 300;
  src: local("Didot"), url("./assests/fonts/Didot.ttf") format("woff");
}
@font-face {
  font-family: "Didot Italic";
  font-weight: 300;
  src: local("Didot Italic"),
    url("./assests/fonts/Didot-Italic.ttf") format("woff");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font: 16px / 32px Europa Light, "Europa Light", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: 16px;
    font: 18px / 32px Europa Light, "Europa Light", sans-serif;
    line-height: normal;
  }
}

@layer utilities {
  .btn-3d {
    border-color: #000;
    border-style: solid;
    border-width: 1px 2px 2px 1px;
    background: #fff;
    /* height: 40px; */
    border-radius: 8px;
    /* padding: 0 20px; */
    white-space: nowrap;
  }
  .btn_black {
    @apply border-black bg-black px-2 py-2 text-white;
  }
  .btn_white {
    @apply border-white bg-white px-8 py-3 text-green-50;
  }
  .btn_white_text {
    @apply border-white bg-white px-8 py-3 text-gray-90;
  }
  .btn_green {
    @apply border-green-50 bg-green-50 px-8 py-5 text-white;
  }
  .btn_dark_green {
    @apply bg-green-90 px-8 py-4 text-white transition-all hover:bg-black;
  }
  .btn_dark_green_outline {
    @apply px-4 py-2 hover:border-[1px] hover:border-green-700 hover:bg-white hover:text-green-700 bg-green-700 text-white rounded-5xl;
  }

  .max-container {
    @apply mx-auto max-w-[1440px];
  }

  .padding-container {
    @apply px-6 lg:px-20 3xl:px-0;
  }

  .flexCenter {
    @apply flex items-center justify-center;
  }

  .flexBetween {
    @apply flex items-center justify-between;
  }

  .flexStart {
    @apply flex items-center justify-start;
  }

  .flexEnd {
    @apply flex items-center justify-end;
  }

  /* FONTS */
  .regular-64 {
    @apply text-[64px] font-[400] leading-[120%];
  }

  .regular-52 {
    @apply text-[52px] font-[400] leading-[120%];
  }

  .regular-40 {
    @apply text-[40px] font-[400] leading-[120%];
  }

  .regular-32 {
    @apply text-[32px] font-[400];
  }

  .regular-24 {
    @apply text-[24px] font-[400];
  }

  .regular-20 {
    @apply text-[20px] font-[400];
  }

  .regular-18 {
    @apply text-[18px] font-[400];
  }

  .regular-16 {
    @apply text-[16px] font-[400];
  }

  .regular-14 {
    @apply text-[14px] font-[400];
  }

  .regular-12 {
    @apply text-[12px] font-[400];
  }

  .medium-14 {
    @apply text-[14px] font-[600];
  }

  .bold-88 {
    @apply text-[88px] font-[700] leading-[120%];
  }

  .bold-64 {
    @apply text-[64px] font-[700] leading-[120%];
  }

  .bold-52 {
    @apply text-[52px] font-[700] leading-[120%];
  }

  .bold-40 {
    @apply text-[40px] font-[700] leading-[120%];
  }

  .bold-32 {
    @apply text-[32px] font-[700] leading-[120%];
  }

  .bold-28 {
    @apply text-[28px] font-[700] leading-[120%];
  }

  .bold-24 {
    @apply text-[24px] font-[700] leading-[120%];
  }

  .bold-20 {
    @apply text-[20px] font-[700];
  }

  .bold-18 {
    @apply text-[18px] font-[700];
  }

  .bold-16 {
    @apply text-[16px] font-[700];
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
