.dev-icons {
  text-align: center;
}

.software-skill-inline > i {
  color: #868e96;
}

.software-skill-inline > i:hover {
  color: #645beb;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.regular-32.dev-icons li {
  animation: pulse 2s infinite alternate; /* Adjust duration as needed */
}
