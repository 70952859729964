.reading-main {
  width: 100%;
  font-family: "Europa Light";
}

.reading-content {
  margin: 20px 12%;
  padding-top: 30px;
}

.photography-intro {
  text-align: center;
}

.reading-quotes {
  font-style: italic;
}

.reading-title {
  font-size: 30px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .reading-content {
    margin: 20px;
  }
}

/* CSS */
.button-54 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  /* color: #000; */
  color: #0e6ba877;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-align: center;
}

.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button-54 {
    padding: 0.25em 0.75em;
  }
}
