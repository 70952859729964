/* .button-main {
  @apply bg-beige-100 text-black hover:text-white hover:bg-beige-200 px-8 py-2;
}

.button-main-active {
  @apply bg-beige-200 text-white hover:text-white hover:bg-beige-200;
  @apply font-bold;
} */

.button-main {
  /* @apply bg-blue-200 text-black hover:text-black hover:bg-blue-300 px-8 py-2; */
  @apply text-black hover:text-black hover:bg-slate-300 px-8 py-2;
}

.button-main-active {
  /* @apply bg-blue-300 text-blue-400 hover:bg-blue-300; */
  @apply bg-slate-200 text-blue-400;
  @apply font-semibold;
}

.button-main,
.button-main-active {
  @apply px-8 py-2 mr-4 mt-4 rounded-xl;
}
